import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'David Veral | Web Developer', // e.g: 'Name | Developer'
  lang: 'es', // e.g: en, es, fr, jp
  description:
    'Me apasiona desarrollar software escalable, crear soluciones efectivas y sobre todo aprender todos los días para crecer profesionalmente.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  es: {
    hello: '¡Hola!',
    title: 'Soy',
    name: 'David Veral',
    subtitle: 'Desarrollador Web FullStack',
    link1: 'Conóceme',
    link2: 'LinkedIn',
  },

  en: {
    hello: 'Hello!',
    title: "I'm",
    name: 'David Veral',
    subtitle: 'Fullstack Web Developer',
    link1: 'About me',
    link2: 'LinkedIn',
  },
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  es: {
    aboutMe: 'Sobre Mí',
    paragraphOne:
      'Desde que tengo uso de razón he tenido una gran pasión por el mundo digital y las tecnologías. Me apasiona desarrollar software escalable, crear soluciones efectivas y sobre todo aprender todos los días para crecer profesionalmente.',
    paragraphTwo:
      'Soy Full Stack Javascript Developer enfocado sobre todo en desarrollo Front End, campo por el que siento especial interés. Me defino como una persona intelectualmente inquieta y creativa.',
    paragraphThree:
      'Experiencia en React, Vue, React Native, Typescript, Bootstrap, SASS, Next.js, Redux, Vuex, API Rest, Mongo, WPO, SPA, JIRA, GIT, Material UI, Styled components...',
    resume: 'https://bit.ly/2QLpCln', // if no resume, the button will not show up
  },
  en: {
    aboutMe: 'About Me',
    paragraphOne:
      'Since I can remember I have had a great passion for the digital world and technologies. I am passionate about developing scalable software, creating effective solutions and above all learning every day to grow professionally.',
    paragraphTwo:
      'I am a Full Stack Javascript Developer focused on Front End development, a field in which I feel special interest. I define myself as an intellectually restless and creative person.',
    paragraphThree:
      'Experience in React, Vue, React Native, Typescript, Bootstrap, SASS, Next.js, Redux, Vuex, API Rest, Mongo, WPO, SPA, JIRA, GIT, Material UI, Styled components...',
    resume: 'https://bit.ly/2QLpCln', // if no resume, the button will not show up
  },
};

// PROJECTS DATA
export const projectsTitle = {
  es: {
    projectsT: 'Proyectos',
    all: 'Todos',
    filter: 'Filtros',
    seeMore: 'Ver proyecto',
    repository: 'Repositorio Github',
    seeMoreP: 'Ver más proyectos en GitHub',
  },

  en: {
    projectsT: 'Projects',
    all: 'All',
    filter: 'Filters',
    seeMore: 'See project',
    repository: 'Github Repository',
    seeMoreP: 'See more projects on GitHub',
  },
};
export const projectsData = [
  {
    id: uuidv1(),
    category: ['api', 'fullstack', 'native'],
    img: 'fitMedia.jpg',
    title: 'Fit Media Log - Mobile app',
    info: {
      es:
        'Creación de ejercicios y entrenamientos, histórico de entrenamientos, planes premium y mucho más. React Native, Firebase, pago con Stripe.',
      en:
        'Creation of exercises and workouts, training log, premium plans and much more. React Native, Firebase, I pay with Stripe.',
    },
    info2: {
      es: 'React Native App - Contacta para más información',
      en: 'React Native App - Contact for more info',
    },
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    category: ['api', 'fullstack', 'native'],
    img: 'buubel.jpg',
    title: 'Buubel - Native mobile app',
    info: {
      es:
        'Gestión y reserva de establecimientos, búsqueda por mapa o listado por distancia o lugar, pago de consumición anticipada. React Native, Firebase, pago con Stripe.',
      en:
        'Management and reservation of establishments, search by map or list by distance or place, payment of anticipated consumption. React Native, Firebase, I pay with Stripe.',
    },
    info2: 'React Native App',
    url: 'https://play.google.com/store/apps/details?id=com.buuble.buubleapp',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    category: ['api', 'react', 'mongo', 'fullstack', 'bootstrap', 'node'],
    img: 'ecommerce-react.jpg',
    title: 'Relakso - eCommerce en React JS',
    info: {
      es:
        'Tienda online Single Page Application sobre React JS. Carrito totalmente dinámico guardándose en LocalStorage si no se está logueado. Búsquedas en tiempo real usando queries de Mongo',
      en:
        'Single Page Application online store on React JS. Fully dynamic cart being saved in LocalStorage if you are not logged in. Real-time searches using Mongo queries',
    },
    info2: 'Full Stack MERN, Axios, Moongose, Bootstrap, Passport, Material UI.',
    url: 'https://relakso-reactjs-app.herokuapp.com/',
    repo: 'https://github.com/veral-dev/ecommerce-reactjs', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    category: ['api', 'react', 'mongo', 'fullstack', 'node'],
    img: 'tasks.jpg',
    title: { es: 'Gestión de tareas - MERN APP', en: 'To-Do List - MERN APP' },
    info: {
      es:
        'Aplicación fullstack MERN usando React Hooks, useContext, React Router Dom, autenticación etc. así como creación de una API rest',
      en:
        'MERN fullstack application using React Hooks, useContext, React Router Dom, authentication etc. as well as creation of a rest API',
    },
    info2: {
      es:
        'En ella se pueden crear, eliminar, editar y borrar (CRUD) proyectos y tareas, así como poner si las tareas están completadas o no',
      en:
        'In it you can create, delete, edit and delete (CRUD) projects and tasks, as well as put if the tasks are completed or not',
    },
    url: 'https://amazing-leavitt-5f0cbf.netlify.com',
    repo: 'https://github.com/veral-dev/MERN-Tasks-Client', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    category: ['firebase', 'react', 'fullstack', 'node'],
    img: 'ronda.jpg',
    title: 'RondaFit  - React APP',
    info: {
      es:
        'Aplicación fullstack usando React, useContext, React Router Dom, autenticación etc. Backend con Google Firebase y pasarela de pago con PayPal.',
      en:
        'Fullstack application using React, useContext, React Router Dom, authentication etc. Backend with Google Firebase and payment gateway with PayPal.',
    },
    info2: {
      es:
        'Se trata de una aplicación donde, mediante una suscripción anual, tener acceso a videos de fitness, yoga, meditación, running así como un menú en pdf semanal. Todo preparado por profesionales. ',
      en:
        'It is an application where, through an annual subscription, you have access to fitness, yoga, meditation, running videos as well as a weekly pdf menu. All prepared by professionals.',
    },
    url: 'https://rondafit.com/',
    repo: 'https://github.com/veral-dev', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    category: ['firebase', 'react', 'native'],
    img: 'native.jpg',
    title: 'Restaurantes APP para móviles',
    info: {
      es:
        'Aplicación nativa para móviles iOS y Android usando React Native, firebase, autenticación en Facebook etc.',
      en:
        'Native application for iOS and Android mobiles using React Native, firebase, Facebook authentication etc.',
    },
    info2: {
      es:
        'En ella se pueden crear restaurantes, añadirlos como favoritos, puntuarles, buscarlos... similar a TripAdvisor',
      en:
        'In it you can create restaurants, add them as favorites, rate them, search for them ... similar to TripAdvisor',
    },
    url: 'https://expo.io/@veral-dev/5-tenedores',
    repo: 'https://github.com/veral-dev/React-native-restaurants-app', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    category: ['firebase', 'react', 'next', 'styled'],
    img: 'next.jpg',
    title: 'React + Next.js + Firebase',
    info: {
      es:
        'Aplicación usando React Next.js, Syled Components, autenticación, subida de archivos etc.',
      en: 'Application using React Next.js, Syled Components, authentication, file upload etc.',
    },
    info2: {
      es:
        'Aplicación con CRUD de productos y usuarios. Con Next.js esta creada una plantilla por lo que el proyecto es escalable. Como base de datos y storage de archivos he usado Google Firebase',
      en:
        'Application with CRUD of products and users. With Next.js a template is created so the project is scalable. As a database and file storage I have used Google Firebase',
    },
    url: 'https://jolly-leavitt-5e62b9.netlify.com/',
    repo: 'https://github.com/veral-dev/nextjs-react-producthunt-app', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    category: ['react', 'gatsby', 'styled', 'graphql'],
    img: 'hotel.jpg',
    title: 'React + Gatsby + Graphql',
    info: {
      es: 'Aplicación usando React, Gatsby, Syled Components, SSR y DatosCMS',
      en: 'Application using React, Gatsby, Syled Components, SSR and CMS Data',
    },
    info2: {
      es:
        'Página web dinámica nutriéndose desde DatoCMS a través de graphQL. Según se añaden nuevas páginas en el cms automáticamente se crean en la web.',
      en:
        'Dynamic web page fed from DatoCMS through graphQL. As new pages are added to the cms, they are automatically created on the web.',
    },
    url: 'https://xenodochial-aryabhata-ed1c46.netlify.com/',
    repo: 'https://github.com/veral-dev/hotel-website-gatsby', // if no repo, the button will not show up
  },
  // {
  //   id: uuidv1(),
  //   category: ['api', 'react', 'bootstrap', 'redux'],
  //   img: 'redux.jpg',
  //   title: 'CRUD - React, Redux, & Rest API & Axios',
  //   info:
  //     'CRUD completo para creación, edición y eliminado de productos usando React, Redux, Axios, Bootstrap, React Router Dom & Sweet Alerts 2 ',
  //   info2:
  //     'Puedes ver el código en github, si quieres verlo en funcionamiento por favor sigue las instrucciones del README',
  //   url: 'https://github.com/veral-dev/react-redux-crud',
  //   repo: 'https://github.com/veral-dev/react-redux-crud', // if no repo, the button will not show up
  // },
  // {
  //   id: uuidv1(),
  //   category: ['api', 'react', 'styled'],
  //   img: 'cripto.jpg',
  //   title: 'Applicación de criptomonedas',
  //   info:
  //     'Aplicación en React para saber en tiempo real el valor de una criptomoneda en la divisa que elijas.',
  //   info2:
  //     'Para ello he utilizado conexión API a través de axios, Styled components y Hooks personalizados',
  //   url: 'https://ecstatic-dijkstra-b8fe4e.netlify.com/',
  //   repo: 'https://github.com/veral-dev/crypto-money-app', // if no repo, the button will not show up
  // },
  {
    id: uuidv1(),
    category: ['node', 'express', 'bootstrap', 'mongo', 'api'],
    img: 'mastering.jpg',
    title: 'Mastering Hero App',
    info: {
      es: 'Sitio web donde buscar y ver a tus heroes y villanos favoritos.',
      en: 'Website where to find and see your favorite heroes and villains.',
    },
    info2: {
      es:
        'Desarrollado con conexiones API externas utilizando Node JS, Express Js, Mongo DB, Handlebars JS, HTML5, SASS, Bootstrap, Axios y JavaScript',
      en:
        'Developed with external API connections using Node JS, Express Js, Mongo DB, Handlebars JS, HTML5, SASS, Bootstrap, Axios and JavaScript',
    },
    url: 'https://mastering-heroes.herokuapp.com/',
    repo: 'https://github.com/villain-hero-db/mastering-heroes', // if no repo, the button will not show up
  },
  //  {
  //    id: uuidv1(),
  //    category: ['api', 'react', 'styled'],
  //    img: 'recetas.jpg',
  //    title: 'Buscador usando API',
  //    info:
  //      'Se trata de una aplicación en React que busca cocktails y bebidas a partir de un ingrediente',
  //    info2: 'Para ello he utilizado conexión API a través de axios, Material UI y Hooks',
  //    url: 'https://keen-northcutt-c7128f.netlify.com',
  //    repo: 'https://github.com/veral-dev/cocktails-react-app', // if no repo, the button will not show up
  //  },
  {
    id: uuidv1(),
    category: ['javascript'],
    img: 'lakeside.jpg',
    title: 'Javascript & Canvas Videogame',
    info: {
      es: 'Juego arcade de plataformas utilizando HTML5, CSS3, JavaScript y Canvas',
      en: 'Arcade platformer game using HTML5, CSS3, JavaScript and Canvas',
    },
    info2: {
      es: 'Totalmente escalable y gran facilidad para crear nuevos mapas.',
      en: 'Fully scalable and great facility to create new maps.',
    },
    url: 'https://veral-dev.github.io/lakeside-game/',
    repo: 'https://github.com/veral-dev/lakeside-game', // if no repo, the button will not show up
  },
  // {
  //   id: uuidv1(),
  //   category: ['react'],
  //   img: 'seguros.jpg',
  //   title: 'Cotizador de seguros en React',
  //   info:
  //     'Se trata de una aplicación en React que apartir de funciones helper te da el precio exacto de tu seguro cambiando según las opciones que elijas.',
  //   info2: 'App desarrollada en React usando hooks, funciones helpers, formularios, etc.',
  //   url: 'https://hungry-rosalind-b0e758.netlify.com/',
  //   repo: 'https://github.com/veral-dev/insurance-quotes', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: '¿Te gustaría contactar conmigo? Genial!',
  btn: 'Hablemos',
  email: 'mailto:hello@davidveral.es',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/david-vf/',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/veral-dev/',
    },
  ],
};
