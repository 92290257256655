/* eslint-disable global-require */
import React, { useContext, useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';
import './hero.css';

const Header = () => {
  const { hero, language, setLanguage } = useContext(PortfolioContext);
  const { img } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const chooseLanguage = lang => {
    setLanguage(lang);
  };

  const Body = () => {
    if (Object.keys(hero).length > 0) {
      return (
        <>
          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
            <h1 className="hero-title">
              {hero[language].hello}
              <br />
              {hero[language].title}
              <span className="text-color-main"> {hero[language].name}</span>
              <br />
              {hero[language].subtitle}
            </h1>
          </Fade>
          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
            <p className="hero-cta">
              <a className="cta-btn cta-btn--hero" href="#about">
                {hero[language].link1}
              </a>

              <a
                className="ml-5 cta-btn cta-btn--hero"
                href="https://www.linkedin.com/in/david-vf/"
              >
                {hero[language].link2}
              </a>
            </p>
          </Fade>
        </>
      );
    } else return <p>-</p>;
  };

  return (
    <>
      <div style={{ position: 'fixed', right: 10, top: 10, zIndex: 100 }}>
        <Button className="mx-3" variant="info" size="lg" onClick={() => chooseLanguage('en')}>
          <img style={{ height: 15 }} src={require('../../images/flag/GBR.png')} alt="english" />
          English
        </Button>
        <Button className="mx-3" variant="info" size="lg" onClick={() => chooseLanguage('es')}>
          <img style={{ height: 15 }} src={require('../../images/flag/ESP.png')} alt="spanish" />
          Español
        </Button>
      </div>
      <section id="hero" className="jumbotron">
        <Container>
          <Body />
          <div className="shape1">
            <img src={require('../../images/shapes/1.png')} alt="shape" />
          </div>
          <div className="shape2">
            <img src={require('../../images/shapes/2.png')} alt="shape" />
          </div>
          <div className="shape3">
            <img src={require('../../images/shapes/3.png')} alt="shape" />
          </div>
          <div className="shape4">
            <img src={require('../../images/shapes/4.png')} alt="shape" />
          </div>
          <div className="shape5">
            <img src={require('../../images/shapes/5.png')} alt="shape" />
          </div>
          <div className="shape6">
            <img src={require('../../images/shapes/6.png')} alt="shape" />
          </div>
          <div className="shape7">
            <img src={require('../../images/shapes/7.png')} alt="shape" />
          </div>
          <div className="shape8">
            <img src={require('../../images/shapes/8.png')} alt="shape" />
          </div>
          <div className="shape9 rotateme">
            <img src={require('../../images/shapes/9.png')} alt="shape" />
          </div>
          <div className="shape10 rotateme">
            <img src={require('../../images/shapes/10.png')} alt="shape" />
          </div>
        </Container>
      </section>
    </>
  );
};

export default Header;
