/* eslint-disable react/button-has-type */
import React, { useContext, useEffect, useState } from 'react';
import './projects.css';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Projects = () => {
  const { projects, projectsTitle, language } = useContext(PortfolioContext);

  // const { projectsT, all, filter, seeMore, repository } = projectsTitle;

  const [webProjects, setWebProjects] = useState([]);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    setWebProjects(projects);
  }, [projects]);

  const filterCategory = category => {
    const filtered = projects.filter(elm => (elm.category.includes(category) ? elm : null));
    setWebProjects(filtered);
  };

  const filterAll = () => {
    setWebProjects(projects);
  };

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title={projectsTitle[language].projectsT} />
          <Fade bottom duration={1000} delay={600} distance="30px">
            {language === 'es' ? (
              <p>
                *Por confidencialidad, aquí solo muestro algunos proyectos independientes. Por
                favor, contacta conmigo para saber más acerca mi.
              </p>
            ) : (
              <p>
                *For confidentiality, here I only show some independent projects. Please, contact me
                to know more about me.
              </p>
            )}
            <h3 className="filter-title">{projectsTitle[language].filter}</h3>
            <ul className="filter-list">
              <li>
                <button className="filter-btn" onClick={filterAll}>
                  {projectsTitle[language].all}
                </button>
              </li>
              <li>
                <button className="filter-btn" onClick={() => filterCategory('react')}>
                  React JS
                </button>
              </li>
              <li>
                <button className="filter-btn" onClick={() => filterCategory('fullstack')}>
                  Fullstack
                </button>
              </li>
              <li>
                <button className="filter-btn" onClick={() => filterCategory('bootstrap')}>
                  Bootstrap
                </button>
              </li>
              <li>
                <button className="filter-btn" onClick={() => filterCategory('api')}>
                  Api
                </button>
              </li>
              <li>
                <button className="filter-btn" onClick={() => filterCategory('redux')}>
                  Redux
                </button>
              </li>
              <li>
                <button className="filter-btn" onClick={() => filterCategory('next')}>
                  Next.js
                </button>
              </li>
              <li>
                <button className="filter-btn" onClick={() => filterCategory('gatsby')}>
                  Gatsby
                </button>
              </li>
              <li>
                <button className="filter-btn" onClick={() => filterCategory('native')}>
                  React Native
                </button>
              </li>
            </ul>
          </Fade>

          {webProjects.map(project => {
            const { id, title, info, info2, url, repo, img } = project;

            return (
              <Row key={id}>
                <Col lg={6} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={700}
                    delay={200}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">
                        {title[language] || title || 'Project Title'}
                      </h3>
                      <div>
                        <p>{info[language] || info}</p>
                        <p className="mb-4">{info2[language] || info2 || ''}</p>
                      </div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url || '#!'}
                      >
                        {projectsTitle[language].seeMore}
                      </a>

                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          {projectsTitle[language].repository}
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={6} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={600}
                    delay={400}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <a
                        href={url || '#!'}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      >
                        <Tilt
                          options={{
                            reverse: false,
                            max: 8,
                            perspective: 1000,
                            scale: 1,
                            speed: 300,
                            transition: true,
                            axis: null,
                            reset: true,
                            easing: 'cubic-bezier(.03,.98,.52,.99)',
                          }}
                        >
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={id} filename={img} />
                          </div>
                        </Tilt>
                      </a>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="cta-btn cta-btn--hero mb-5"
          href="https://github.com/veral-dev/"
        >
          {projectsTitle[language].seeMoreP}
        </a>
      </Container>
    </section>
  );
};

export default Projects;
