import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { headData } from '../mock/data';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
    const { lang } = headData;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>David Veral | Web Developer</title>
                <html lang={lang} />
                <meta
                    name="description"
                    content="Me apasiona desarrollar software escalable, crear soluciones efectivas y sobre todo aprender todos los días para crecer profesionalmente."
                />
                <meta
                    property="og:title"
                    content="David Veral | Web Developer"
                />
                <meta property="og:url" content="https://davidveral.es/" />
                <meta
                    property="og:description"
                    content="I am passionate about developing scalable software, creating effective solutions and above all learning every day to grow professionally."
                />
                <meta
                    property="og:site_name"
                    content="David Veral | Web Developer Website"
                />
                <meta property="og:type" content="Website" />
            </Helmet>
            <App />
        </>
    );
};
