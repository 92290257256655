import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import './about.css';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
    const { about, language } = useContext(PortfolioContext);
    const { img } = about;

    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);

    const Body = () => {
        if (Object.keys(about).length > 0) {
            return (
                <>
                    <Fade
                        left={isDesktop}
                        bottom={isMobile}
                        duration={1000}
                        delay={1000}
                        distance="30px"
                    >
                        <div className="about-wrapper__info">
                            <p className="about-wrapper__info-text">
                                {about[language].paragraphOne}
                            </p>
                            <p className="about-wrapper__info-text">
                                {about[language].paragraphTwo}
                            </p>
                            <p className="about-wrapper__info-text">
                                {about[language].paragraphThree}
                            </p>
                            {about[language].resume && (
                                <span className="d-flex mt-3">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="cta-btn cta-btn--resume cv"
                                        href={about[language].resume}
                                    >
                                        Currículum
                                    </a>
                                </span>
                            )}
                        </div>
                    </Fade>
                </>
            );
        } else return <p>-</p>;
    };

    const Header = () => {
        if (Object.keys(about).length > 0) {
            return (
                <>
                    <Title title={about[language].aboutMe} />
                    <Fade bottom duration={1000} delay={600} distance="30px">
                        <ul className="tech-list">
                            <li>Javascript</li>
                            <li>React</li>
                            <li>React Native</li>
                            <li>Vue</li>
                            <li>Mongo DB</li>
                            <li>Express</li>
                            <li>Node</li>
                            <li>Html5</li>
                            <li>Css3</li>
                        </ul>
                    </Fade>
                </>
            );
        } else return <p>-</p>;
    };

    return (
        <section id="about">
            <Container>
                <Header />
                <Row className="about-wrapper">
                    <Col md={6} sm={12}>
                        <Fade
                            bottom
                            duration={1000}
                            delay={600}
                            distance="30px"
                        >
                            <div className="about-wrapper__image">
                                <AboutImg
                                    alt="profile picture"
                                    filename={img}
                                />
                            </div>
                        </Fade>
                    </Col>
                    <Col md={6} sm={12}>
                        <Body />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
